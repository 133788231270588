import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { WarehousesController } from './warehouses.controller'
import i from '../../../translations/i'
import { Button, Space, Table, Row, Col, Input, Select, InputNumber, Upload, notification, Switch } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Titles } from '../../../layouts/Titles'
import { useParams } from 'react-router-dom'
import { ParamTypes } from '../../../helpers/types.interfaces'
import { storeLocal } from '../../../helpers/stores'
import cities from '../cities/cities.controller'
import controller from "../cities/cities.controller";

const Warehouses = new WarehousesController()

export const Storages = observer(() => {

    useEffect(() => {
        return () => {
            Warehouses.get()
        }
    }, [])

    const buttons = [
        { title: i.t('def:actions.create'), href: `/settings/warehouses/0`, type: 'primary' }
    ]

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        {
            title: i.t('settings:cities.form.enabled'),
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text: any, record: any) => (
              <>{text === 0 ? i.t('def:no') : i.t('def:yes')}</>
            )
        },
        { title: i.t('def:name'), dataIndex: 'name', key: 'name' },
        {
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        href={`/settings/warehouses/${record.id}`}
                    />
                    <Button
                        type="dashed"
                        shape="circle"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => Warehouses.remove(record.id)}
                    />
                </Space>
            )
        }
    ]

    return(
        <>
            <Titles
                title={i.t('settings:storage.title')}
                buttons={buttons}
            />
            {storeLocal.get('pricing_plan') >= 2 &&
                <>
                    <Table
                        columns={columns}
                        dataSource={Warehouses.list}
                        pagination={{
                            position: ['bottomLeft'],
                            hideOnSinglePage: true,
                            current: Number(Warehouses.pagination.page),
                            pageSize: Warehouses.pagination.limit,
                            total: Warehouses.pagination.total,
                            showSizeChanger: false,
                            onChange: (e) => Warehouses.setPage(e)
                        }}
                    />
                </>
            }
        </>
    )
})

export const StorageElement = observer(() => {

    const { elementID } = useParams<ParamTypes>()

    useEffect(() => {
        cities.pagination.limit = 999
        cities.get()
        return () => {
            // @ts-ignore
            if(elementID > 0) Warehouses.getElement(elementID)
            Warehouses.getZones()
            Warehouses.getPlaces()
        }
    }, [])

    const buttons = [
        { title: i.t('def:actions.back'), href: '/settings/warehouses', type: 'dashed' },
        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
    ]

    return(
        <>
            <form onSubmit={(e) => Warehouses.save(e)}>
                <Titles
                    title={i.t('settings:storage.titleDetail')}
                    buttons={buttons}
                />
                <Row gutter={[20, 20]}>
                    <Col xs={24} className="switch-block">
                        <div className="element">
                            <div></div>
                            <Switch
                              checked={Warehouses.element.enabled}
                              onChange={(e) => Warehouses.onChange('enabled', e)}
                              size="small"
                            />
                            <div>
                                <span>{i.t('settings:cities.form.enabled')}</span>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <label>{i.t('def:name')}</label>
                        <Input
                            name="name"
                            placeholder={i.t('def:name')}
                            value={Warehouses.element.name}
                            onChange={(e) => Warehouses.onChange('name', e.target.value)}
                        />
                    </Col>
                    <Col xs={12}>
                        <label>{i.t('def:id_crm')}</label>
                        <Input
                            name="name"
                            placeholder={i.t('def:id_crm')}
                            value={Warehouses.element.id_crm}
                            onChange={(e) => Warehouses.onChange('id_crm', e.target.value)}
                        />
                    </Col>
                    {/*<Col span={24}>*/}
                    {/*    <YandexSetPin coordinates={[Storage.element.latitude, Storage.element.longitude]} set={re} />*/}
                    {/*</Col>*/}
                    <Col xs={24}>
                        <label>{i.t('settings:storage.city')}</label>
                        <Select value={Warehouses.element.city_id} style={{ width: 100 + '%' }} onChange={(e) => Warehouses.onChange('city_id', e)}>
                            {cities.list.map((el: any, i) => <Select.Option value={el.id} key={i}>{el.name}</Select.Option>)}
                        </Select>
                    </Col>
                    <Col xs={24} md={12}>
                        <label>{i.t('settings:storage.latitude')}</label>
                        <InputNumber
                            style={{ width: 100 + '%' }}
                            controls={false}
                            placeholder={i.t('settings:storage.latitude')}
                            value={Warehouses.element.latitude}
                            onChange={(e) => Warehouses.onChange('latitude', e)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <label>{i.t('settings:storage.longitude')}</label>
                        <InputNumber
                            style={{ width: 100 + '%' }}
                            controls={false}
                            placeholder={i.t('settings:storage.longitude')}
                            value={Warehouses.element.longitude}
                            onChange={(e) => Warehouses.onChange('longitude', e)}
                        />
                    </Col>
                    <Col xs={24}>
                        <label>{i.t('settings:storage.zones')}</label>
                        <Select mode="multiple" value={Warehouses.element.zones} style={{ width: 100 + '%' }} onChange={(e) => Warehouses.onChange('zone', e)}>
                            {Warehouses.zones.map((el: any, i) => <Select.Option value={el.id} key={i}>{el.name}</Select.Option>)}
                        </Select>
                    </Col>
                    <Col xs={24}>
                        <label>{i.t('settings:storage.places')}</label>
                        <Select mode="multiple" value={Warehouses.element.places} style={{ width: 100 + '%' }} onChange={(e) => Warehouses.onChange('place', e)}>
                            {Warehouses.places.map((el: any, i) => <Select.Option value={el.id} key={i}>{el.name}</Select.Option>)}
                        </Select>
                    </Col>
                    <Col xs={24} md={12}>
                        <label>{i.t('settings:cities.form.image')}</label>
                        {Warehouses.element.picture?.id > 0 ? (
                            <Row gutter={[10, 10]}>
                                <Col xs={24}>
                                    <img src={Warehouses.element.picture.base + Warehouses.element.picture.uri} alt='' style={{maxWidth: '250px'}}/>
                                </Col>
                                <Col xs={24}>
                                    <Button
                                        onClick={e => Warehouses.removeImage(elementID)}
                                    >
                                        {i.t('def:deletePicture')}
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            <Upload
                                beforeUpload={(info) => {
                                    Warehouses.upload(info)
                                    return false
                                }}
                                listType="picture-card"
                                className="photo-uploader"
                                maxCount={1}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>{i.t('def:image.upload')}</div>
                                </div>
                            </Upload>
                        )}
                    </Col>
                </Row>
            </form>

        </>
    )
})