import { observer } from 'mobx-react-lite'
import { Titles } from '../../layouts/Titles'
import controller from './dashboard.controller'
import i from '../../translations/i'
import { Button, Col, Row, Space, Tag, Tooltip, Typography } from 'antd'
import { CheckCircleOutlined, ExclamationOutlined } from '@ant-design/icons'
import React, { memo } from 'react'
import './dashboard.less'
import iosIcon from '../../assets/images/systems-logotypes/ios.svg'
import androidIcon from '../../assets/images/systems-logotypes/android.svg'
import appGalleryIcon from '../../assets/images/systems-logotypes/app_gallery.png'
import { useEffect } from 'react'
import dashboardController from './dashboard.controller'
import dayjs from 'dayjs'
import { storeLocal } from '../../helpers/stores'
import { getRateName } from '../../helpers/language.helper'
import projectController from '../settings/project/project.controller'

interface ElemInterface {
    label: string
    uri: string
    number: boolean
    value: string | number
}

const Elem = memo(({ label, uri, number, value }: ElemInterface) => (
    <Col xs={{ span: 24 }} md={{ span: 6 }} key={`dashboard_uri`}>
        <a href={uri}>
            <div className="dashboard">
                <h3>{label}</h3>
                <p className={number ? 'num' : ''}>{value}</p>
            </div>
        </a>
    </Col>
))

export const Dashboard = observer(() => {

    useEffect(() => {
        return () => {
            dashboardController.getStat()
            dashboardController.getProject()
            projectController.get()
        }
    }, [])

    return (
        <>
            <Titles title={i.t('dashboard:title')} />

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Row className="dashboard">
                        <Col span={12}>
                            <Typography.Title level={3}>{dashboardController.project.name}</Typography.Title>
                            <Typography.Title level={5}>Bundle: {dashboardController.project.bandl}</Typography.Title>
                            <p>
                                {i.t('def:tariffs.rate')} <Tag color="success">{getRateName(storeLocal.get('pricing_plan'))}</Tag> &nbsp;
                                <Tag
                                    color={dayjs().unix() >= dashboardController.project.datepayend ? 'error' : 'success'}
                                    icon={dayjs().unix() >= dashboardController.project.datepayend ? <ExclamationOutlined /> : <CheckCircleOutlined />}
                                >
                                    {dashboardController.project.datepayend > 0 ? dayjs(dashboardController.project.datepayend * 1000).format('DD.MM.YYYY') : 'Не оплачено'}
                                </Tag>
                            </p>
                        </Col>
                        <Col span={12} className="right">
                            <Space>
                                {dashboardController.project.urlios !== ''
                                    ? <>
                                        <Tooltip placement="topRight" title={i.t('def:copyAppStore')}>
                                            <div className="device" onClick={() => controller.copyLink(dashboardController.project.urlios)}><img src={iosIcon} alt='' /></div>
                                        </Tooltip>
                                    </>
                                    : <>
                                        <Tooltip placement="topRight" title={i.t('dashboard:links.appNotFound')}>
                                            <div className="device disabled"><img src={iosIcon} alt='' /></div>
                                        </Tooltip>
                                    </>
                                }

                                {dashboardController.project.urlandroid !== ''
                                    ? <>
                                        <Tooltip placement="topRight" title={i.t('def:copyGooglePlay')}>
                                            <div className="device" onClick={() => controller.copyLink(dashboardController.project.urlandroid)}><img src={androidIcon} alt='' /></div>
                                        </Tooltip>
                                    </>
                                    : <>
                                        <Tooltip placement="topRight" title={i.t('dashboard:links.appNotFound')}>
                                            <div className="device disabled"><img src={androidIcon} alt='' /></div>
                                        </Tooltip>
                                    </>
                                }

                              {dashboardController.project.url_app_gallery !== ''
                                ? <>
                                  <Tooltip placement="topRight" title={i.t('def:App Gallery')}>
                                    <div className="device" onClick={() => controller.copyLink(dashboardController.project.url_app_gallery)}><img src={appGalleryIcon} alt='' /></div>
                                  </Tooltip>
                                </>
                                : <>
                                  <Tooltip placement="topRight" title={i.t('dashboard:links.appNotFound')}>
                                    <div className="device disabled"><img src={appGalleryIcon} alt='' /></div>
                                  </Tooltip>
                                </>
                              }
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <br />

            <Row gutter={[20, 20]}>
                {projectController?.project?.telegram_bot_token && projectController.project.telegram_bot_token !== ''
                    ? <></>
                    : <Col span={24}>
                        <div className="dashboard-alert dashboard-alert-info">
                            <Row justify="space-around" align="middle">
                                <Col span={16}>{i.t('def:telegram.settingNot')}</Col>
                                <Col span={8} style={{ textAlign: 'right' }}>
                                    <Button href="/settings/project">{i.t('def:setting')}</Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                }
            </Row>

            <br/>

            <Row gutter={[20, 20]}>
                <Elem label={i.t('dashboard:stat.products')} uri="/catalog/products" value={dashboardController.stat.products} number={true} />
                <Elem label={i.t('dashboard:stat.categories')} uri="/shop/catalog" value={dashboardController.stat.categories} number={true} />
                <Elem label={i.t('dashboard:stat.orders')} uri="/shop/orders" value={dashboardController.stat.orders} number={true} />
                <Elem label={i.t('dashboard:stat.users')} uri="/users" value={dashboardController.stat.users} number={true} />
            </Row>

        </>
    )
})
