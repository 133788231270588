import { makeObservable, observable, runInAction } from 'mobx'
import { openApi } from '../../helpers/request'
import { FormEvent } from 'react'
import { notification } from 'antd'
import i from '../../translations/i'

export interface AppInterface {
    id: number
    key: string
    enabled: boolean
    installed: boolean
    config: any
    name_class: string
    category: string
    min_rate: number
    icon: string
}

export interface AppConnectedInterface {
    id: number
    store: AppInterface
}

export interface AppSettingsInterface {
    id: number
    key: string
    required: boolean
    type: string
    save?: boolean
    delete? : boolean
}

class __apps {

    list: AppInterface[] = []
    installed: AppInterface[] = []
    data: any = null
    send_data: any = {}

    modal: boolean = false

    constructor() {
        makeObservable(this, {
            list: observable,
            data: observable,
            modal: observable,
            send_data: observable,
            installed: observable,
        })
    }

    getList() {
        openApi('get', '/store')
            .then(result => {
                console.log(result)
                runInAction(() => {
                    this.list = result.data.other
                    this.installed = result.data.installed
                })
            })
          .catch(err => console.log(err.response))
    }

    async getOne(id: number) {
        openApi('get', `/store/${id}`)
            .then(result => {
                console.log(result)
                runInAction(() => {
                    this.data = result.data
                    this.modal = true
                })
            })
          .catch(error => console.log(error.response))
    }

    async openModal(id: number) {
        await this.getOne(id)

    }

    closeModal() {
        this.data = null
        this.modal = false
        this.getList()
    }

    saveApplication() {
        const data = new FormData()
        if(this.data.enabled) data.append(`enabled`, '1')
        data.append(`data`, JSON.stringify(this.data.data))

        this.data.fields.map((field: any, index: number) => {
            if(field.id && field.id > 0) data.append(`fields[${index}][id]`, `${field.id}`)
            if(field.comment) data.append(`fields[${index}][comment]`, field.comment)
            if(field.field_key) data.append(`fields[${index}][field_key]`, field.field_key)
            if(field.entity_type) data.append(`fields[${index}][entity_type]`, field.entity_type)
            if(field.entity_id) data.append(`fields[${index}][entity_id]`, field.entity_id)
            if(field.data.value && typeof field.data.value === 'string') data.append(`fields[${index}][data][value]`, field.data.value)
            data.append('is_data', '1')
        })

        openApi('post', `/store/${this.data.id}`, data)
            .then(result => {
                console.log(result)
                this.getOne(result.data.app_id)
                notification.success({
                    message: i.t('def:success.success'),
                    description: i.t('def:success.edit'),
                })
            })
            .catch(error => {
                console.log(error.response)
                notification.error({
                    message: i.t('def:errors.error'),
                    description: error.response.data.message
                })
            })
    }

    save(e: FormEvent) {
        e.preventDefault()
        this.saveApplication()

    }

    saveSettings(e: FormEvent, id: any) {
        e.preventDefault()

        this.save(id)
    }

    setValue(value: string, ind: number, id: number, self: any) {
        // @ts-ignore
        let index = this.setting.self[ind].findIndex((store: any) => store.store_setting_id === id)
        console.log(index)

        if(index > -1) {
            // @ts-ignore
            runInAction(() => this.setting.self[ind][index].value = value)
        }
    }


    delete(id: any) {
        openApi('delete', `/store/${id}`)
            .then(result => {
                console.log(result)
                this.getList()
                notification.success({
                    message: i.t('def:success.success'),
                    description: i.t('def:success.delete'),
                })
            })
            .catch(error => {
                notification.error({
                    message: i.t('def:errors.error'),
                    description: error.response.data.message
                })
            })
    }

    setEvent(e: string) {
        let array = []
        if(this.data.data.eventsDisabled) {
            array = this.data.data.eventsDisabled
        }

        if(array.find((el: string) => el === e)) {
            array = array.filter((el: string) => el !== e)
        } else {
            array.push(e)
        }

        this.data.data.eventsDisabled = array
    }

    addCustomField() {
        this.data.fields.push({
            field_key: null,
            comment: null,
            entity_type: null,
            entity_id: null,
            data: {
                value: ''
            }
        })
    }

    deleteCustomField(index: number) {

    }
}

export default new __apps()