import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../../helpers/request'
import { message } from 'antd'
import { FormEvent } from 'react'

interface ActiveInterface {
    id: number
    enabled: boolean
    id_crm: string | null
    name: string
    price: any
    minprice: any
    maxprice: any
    coordinates: any[]
    coordinatesGoogle: any[]
}

export class zonesController {

    list: any[] = [];

    center: any[] = [0, 0];
    zoom = 1;
    active: ActiveInterface = {
        id: -1,
        enabled: true,
        id_crm: null,
        name: "",
        price: null,
        minprice: null,
        maxprice: null,
        coordinates: [],
        coordinatesGoogle: []
    }

    modal = false;

    constructor() {
        makeAutoObservable(this)
    }

    onChangeMap(e: any) {
        // console.log(e.get('target').getCenter());
        // this.setCenter(e.get('target').getCenter())
        // this.zoom = e.get('target')._zoom;
    }

    openModal() {
        this.modal = true;
    }

    closeModal() {
        this.modal = false;
    }

    locationUser(ymaps: any) {
        let _this = this;
        ymaps.geolocation.get({
            provider: 'browser',
            mapStateAutoApply: true
        }).then(function (result: any) {
            console.log(result.geoObjects.get(0).geometry.getCoordinates());
            _this.setCenter(result.geoObjects.get(0).geometry.getCoordinates());
        });
    }

    getAllZone() {
        openApi('get', "/setting/delivery_zone")
            .then(success => {
                console.log(success.data)
                this.list = success.data

                let coordinatesList: any [] = []

                this.list.map((el: any, index: number) => {
                    // @ts-ignore
                    let coordinatesGoogle = []
                    el.coordinates.map((item: any) => {
                        coordinatesGoogle.push({
                            lat: item[0],
                            lng: item[1]
                        })

                        coordinatesList.push(item)
                    })

                    // @ts-ignore
                    this.list[index].coordinatesGoogle = coordinatesGoogle
                })

                let centerAndZoom = this.getMapZoomAndCenter(coordinatesList)

                console.log(centerAndZoom)

                this.center = centerAndZoom.center
                this.zoom = centerAndZoom.zoom

            })
            .catch(error => console.log(error))
    }

    setCenter(coordinates: any[]) {
        this.center = coordinates;
    }

    addNewZone() {
        let item = 0.1 - (this.zoom / 100);

        let coordinates = [
            [this.center[0], this.center[1]],
            [this.center[0], this.center[1] - item],
            [this.center[0] - item, this.center[1] - item],
            [this.center[0] - item, this.center[1]]
        ];

        let coordinatesGoogle = [
            { lat: this.center[0], lng: this.center[1] },
            { lat: this.center[0], lng: this.center[1] - item },
            { lat: this.center[0] - item, lng: this.center[1] - item },
            { lat: this.center[0] - item, lng: this.center[1] },
        ]

        // @ts-ignore
        this.active.coordinates = coordinates;
        this.active.coordinatesGoogle = coordinatesGoogle;
        this.active.id = 0;
    }

    clearActive() {
        this.active = {
            id: -1,
            enabled: true,
            id_crm: null,
            name: "",
            price: null,
            minprice: null,
            maxprice: null,
            coordinates: [],
            coordinatesGoogle: []
        }
    }

    saveActive() {

        let facts = new FormData();
        facts.append("name", this.active.name);
        if(this.active.id_crm) facts.append("id_crm", this.active.id_crm);
        facts.append("enabled", `${+ this.active.enabled}`);
        facts.append("price", `${this.active.price}`);
        facts.append("minprice", `${this.active.minprice}`);
        facts.append("maxprice", `${this.active.maxprice}`);
        facts.append("coordinates", JSON.stringify(this.active.coordinates));

        // Запрос на сохранение
        let url = "/setting/delivery_zone";

        if(this.active.id > 0) {
            url = url + "/" + this.active.id;
        }

        openApi('post', url, facts)
            .then(success => {
                this.clearActive()
                this.getAllZone()
                this.modal = false;
            })
            .catch(error => console.log(error));
    }

    onChange(e: any) {
        this.active.coordinates = e.get("target").geometry._coordPath._coordinates[0];
    }

    onChangeGoogle(e: number[]) {
        this.active.coordinates = e
    }

    select(id: number) {
        if(this.active.id > -1) {
            message.error("Сохраните или удалите активную зону");
        } else {
            this.active = this.list.find(el => el.id === id);
        }
    }

    deleteByID(ID: any) {
        openApi('delete', `/setting/delivery_zone/${ID}`)
            .then(success => {
                console.log(success);
                this.getAllZone()
            })
            .catch(error => console.log(error));
    }

    edit(ID: any) {
        this.active = {
            id: this.list.find(el => el.id === ID).id,
            enabled: this.list.find(el => el.id === ID).enabled,
            name: this.list.find(el => el.id === ID).name,
            id_crm: this.list.find(el => el.id === ID).id_crm,
            price: this.list.find(el => el.id === ID).price,
            minprice: this.list.find(el => el.id === ID).minprice,
            maxprice: this.list.find(el => el.id === ID).maxprice,
            coordinates: this.list.find(el => el.id === ID).coordinates,
            coordinatesGoogle: this.list.find(el => el.id === ID).coordinatesGoogle
        }
        this.modal = true;
    }

    delete() {
        if(this.active.id > 0) {
            this.deleteByID(this.active.id)
            this.clearActive();
        } else {
            this.clearActive();
        }
    }

    saveDataZone(e: FormEvent) {
        e.preventDefault()
        this.closeModal()

        if(this.active.id < 1) {
            this.addNewZone()
        }

        let centerAndZoom = this.getMapZoomAndCenter(this.active.coordinates, true)
        this.setCenter(centerAndZoom.center)
        this.zoom = centerAndZoom.zoom

        window.scrollTo(0,0)
    }

    onChangeInput(name: string, value: any) {
        // @ts-ignore
        this.active[name] = value;
    }

    getMapZoomAndCenter(list: any [], contolZoom: boolean = false): {
        center: any[],
        zoom: number
    } {
        let sumLat = 0
        let sumLng = 0
        let numCoords = 0
        let minLat = Infinity
        let maxLat = -Infinity
        let minLng = Infinity
        let maxLng = -Infinity

        list.map((item: any) => {
            sumLat += Number(item[0])
            sumLng += Number(item[1])
            numCoords++

            if (item[0] < minLat) minLat = Number(item[0]);
            if (item[0] > maxLat) maxLat = Number(item[0]);
            if (item[1] < minLng) minLng = Number(item[1]);
            if (item[1] > maxLng) maxLng = Number(item[1]);
        })

        console.log(minLat, maxLat)
        console.log(minLng, maxLng)

        let zoom = Math.floor(((minLat / maxLat) - (minLng / maxLng)) * 8)
        // if(zoom < 9 && contolZoom) zoom = 9

        return {
            center: [sumLat / numCoords, sumLng / numCoords],
            zoom: 12
        }
    }
}