import { makeAutoObservable, runInAction } from 'mobx'
import { FormEvent } from 'react'
import CryptoJS from 'crypto-js'
import { storeLocal, storeSession } from '../../../helpers/stores'
import {openApi} from '../../../helpers/request'
import i from '../../../translations/i'
import { notification } from 'antd'
import layoutController from '../../../layouts/controllers/layout.controller'

const SECRET_KEY = process.env.REACT_APP_HASH_KEY || ''

class userController {

    dataLogin = {
        login: '',
        password: '',
    }

    dataRegister = {
        name: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: '',
    }

    dataForgot = {
        login: '',
        password: '',
        passwordConfirm: '',
    }

    dataVerify = {
        code: '',
    }

    verifyStatus = false

    error = ''

    projects: any[] = [
        {}
    ]

    constructor() {
        makeAutoObservable(this)
    }

    onChangeLogin(name: string, value: any) {
        if(this.error !== '') this.error = ''
        // @ts-ignore
        this.dataLogin[name] = value
    }

    onChangeRegister(name: string, value: any) {
        if(this.error !== '') this.error = ''
        // @ts-ignore
        this.dataRegister[name] = value
    }

    onChangeForgot(name: string, value: any) {
        if(this.error !== '') this.error = ''
        // @ts-ignore
        this.dataForgot[name] = value
    }

    async login(e: FormEvent) {
        e.preventDefault()

        let data = new FormData()
        data.append('email', this.dataLogin.login || '')
        data.append('password', CryptoJS.SHA1(this.dataLogin.password).toString() || '')

        openApi('post', '/manager/login', data)
            .then(async result => {

                if(!result.data.manager.verified) {
                    storeSession.set('verification_email', result.data.manager.email)
                    window.location.href = '/verify'
                    return
                }

                const pack = await this.pack(result.data)
                if (pack) {
                    window.location.href = '/select'
                }

                // storeLocal.set('bundle', result.bundle)
                // if(result.project && result.project.pricing_plan >= 0) storeLocal.set('pricing_plan', result.project.pricing_plan)
                // const pack = await this.pack(result)
                // if (pack) {
                //     window.location.href = '/'
                // }
            })
            .catch(err => {
                this.error = err.response.data.data.message ?? 'error'
                return false
            })
    }

    async register(e: FormEvent) {
        e.preventDefault()

        if(this.dataRegister.password !== this.dataRegister.confirmPassword) {
            this.error = 'Введенные пароли не совпадают'
            return false
        }

        if(this.dataRegister.phone === '') {
            this.error = 'Укажите контактный номер телефона'
            return false
        }

        let firstName = '',
            lastName = '',
            surName = ''

        if(this.dataRegister.name !== '') {
            const fio = this.dataRegister.name.split(' ')
            if(fio.length > 1) {
                firstName = fio[1]
                lastName = fio[0]
                surName = fio[2] && fio[2]
            } else {
                firstName = fio[0]
            }
        }

        const data = new FormData()
        data.append('email', this.dataRegister.email)
        data.append('password', CryptoJS.SHA1(this.dataRegister.password).toString())
        data.append('firstname', firstName)
        data.append('lastname', lastName)
        data.append('surname', surName)
        data.append('phone', this.dataRegister.phone)

        if(storeLocal.get('service_utm')) {
            for (let key in storeLocal.get('service_utm')) {
                data.append(key, storeLocal.get('service_utm')[key])
            }
        }

        openApi('post', '/manager/register', data)
            .then(result => {
                storeSession.set('verification_email', this.dataRegister.email)
                window.location.href = '/verify'
                return false
            })
            .catch(err => {
                console.log(err.response)
                this.error = err.response.data.data.message
                return false
            })

    }

    async pack(result: any) {
        if(result.access_token) {
            let objectAuth = {
                access_token: result.access_token,
                refresh_token: result.refresh_token,
                expires: result.expires
            }

            let user = result.manager

            this.encrypt('auth', objectAuth)

            if(result.project) this.encrypt('project', result.project)
            if(result.modules) this.encrypt('modules', result.modules ?? [])

            this.encrypt('user', user)

            return true
        } else {
            return false
        }
    }

    async access() {
        const tokens = await this.decrypt()
        if(tokens && tokens.refresh_token && tokens.refresh_token !== '') {
            const result = await openApi('post', '/manager/access_token')
            if(result) {
                await this.pack(result.data)
            }
        } else {
            return false
        }
    }

    encrypt(title: string, data: any) {
        if(SECRET_KEY !== '') {
            const crypt = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString()
            storeLocal.set(title, crypt)
        }
    }

    async decrypt(title: string = 'auth') {
        if(storeLocal.get(title)) {
            const bytes = CryptoJS.AES.decrypt(storeLocal.get(title), SECRET_KEY)
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        } else {
            return false
        }
    }

    decryptName(name = '', project_setting = false) {
        if(storeLocal.get('project')) {

            const bytes = CryptoJS.AES.decrypt(storeLocal.get('project'), SECRET_KEY)
            const project = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
            console.log(project)

            if(project_setting) return project.project[name]
            else return project[name]
        } else {
            return false
        }
    }

    isModule(module: number) {
        console.log(storeLocal.get('modules'))
        if(storeLocal.get('modules')) {

            const bytes = CryptoJS.AES.decrypt(storeLocal.get('modules'), SECRET_KEY)
            const modules = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

            return modules.findIndex((el: number) => el === module) > -1
        } else {
            return false
        }
    }

    async newCode() {
        const email = storeSession.get('verification_email') || undefined

        console.log(email)
        if(email) {
            const data = new FormData()
            data.append('email', email)
            data.append('type', 'register')

            openApi('post', '/manager/new_code', data)
                .then(result => {
                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.code')
                    })
                })
                .catch(err => {
                    console.log(err)
                    notification.error({
                        message: i.t('def:errors.error'),
                        description: i.t('def:errors.code')
                    })
                })
        } else {
            window.location.href = '/login'
            return false
        }
    }

    onChangeVerify(value: string) {
        this.dataVerify.code = value
    }

    async verify(e: FormEvent) {
        e.preventDefault()

        const email = storeSession.get('verification_email') || undefined

        if(email) {
            const data = new FormData()
            data.append('email', email)
            data.append('code', this.dataVerify.code)

            openApi('post', '/manager/verify', data)
                .then(result => {
                    storeSession.remove('verification_email')
                    storeSession.remove('verification_type')
                    this.pack(result.data)
                        .then(res => {
                            window.location.href = '/'
                        })
                        .catch(err => {
                            window.location.href = '/'
                        })
                })
                .catch(err => {
                    console.log(err)
                    this.error = err.message
                    return false
                })
        } else {
            window.location.href = '/login'
            return false
        }
    }

    getProjectsByManager() {
        openApi('post', '/manager/projects')
            .then(result => {
                runInAction(() => this.projects = result.data)
            })
            .catch(error => console.log(error.response))
    }

    async changeProject(id: any) {
        openApi('post', `/manager/set_project/${id}`)
            .then(async result => {
                console.log(result.data)

                if(result.data.bundle) storeLocal.set('bundle', result.data.bundle)
                if(result.data.project && result.data.project.pricing_plan >= 0) storeLocal.set('pricing_plan', result.data.project.pricing_plan)
                storeSession.remove('project_units')

                if(result.bundle) storeLocal.set('bundle', result.bundle)
                if(result.project && result.project.pricing_plan >= 0) storeLocal.set('pricing_plan', result.project.pricing_plan)

                if(result.data && result.data.project && result.data.project.black_mark)
                    storeLocal.set('black_mark', !!result.data.project.black_mark)
                else storeLocal.set('black_mark', false)

                if(result.data.manager.role)
                    storeLocal.set('role', result.data.manager.role)
                else
                    storeLocal.remove('role')


                const pack = await this.pack(result.data)
                if (pack) {
                    window.location.href = '/'
                }
            })
          .catch(err => console.log(err.response))
    }

    reload() {
        setInterval(() => {
            openApi('get', '/manager/reload')
                .then(async result => {
                    console.log(result.data)
                    const pack = await this.pack(result.data)
                    if (pack) {
                        window.location.href = '/'
                    }
                })
                .catch(error => {
                    console.log('err')
                })
        }, 30000)
    }
}

export default new userController()