import { observer } from 'mobx-react-lite'
import { FormEvent, useEffect } from 'react'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import __admin, { AdminInterface } from './__admin'
import { Button, Col, Input, Row, Space, Table, Tag, Select, Divider, Alert, Popconfirm, Switch } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { runInAction } from 'mobx'
import { storeLocal } from '../../../helpers/stores'
import userController from "../../user/controllers/user.controller";

export const AdminList = observer(() => {

    useEffect(() => {
        return () => __admin.getList()
    }, [])

    const buttons = [
        { title: i.t('def:actions.create'), href: `/settings/managers/0`, type: 'primary' }
    ]

    const columns = [
        {
            title: i.t('def:type'),
            key: 'rights_type',
            render: (record: any) => (
                <>
                    {record.right.rights === 0 && <Tag color="success">{i.t('def:managers.admin')}</Tag>}
                    {record.right.rights === 1 && <Tag color="processing">{i.t('def:managers.operator')}</Tag>}
                    {record.right.rights === 2 && <Tag color="warning">{i.t('def:managers.picker')}</Tag>}
                    {record.right.rights === 3 && <Tag color="geekblue">{i.t('def:managers.courier')}</Tag>}
                  {record.right.rights === 4 && <Tag color="blue">{i.t('def:managers.seller')}</Tag>}
                </>
            )
        },
        {
            title: i.t('def:name'),
            key: 'name',
            render: (record: AdminInterface) => `${record.firstname ?? ''} ${record.lastname ?? ''}`
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        // {
        //     title: 'Активность',
        //     dataIndex: 'enabled',
        //     key: 'enabled',
        //     render: (text: any) => (
        //         <>{text === 0 ? <Tag color="error">{i.t('def:no')}</Tag> : <Tag color="success">{i.t('def:yes')}</Tag>}</>
        //     )
        // },
        {
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<EditOutlined />}
                        href={`/settings/managers/${record.id}`}
                    />
                    <Popconfirm
                        title={i.t('def:confirms.delete')}
                        onConfirm={() => __admin.unLink(record.id)}
                        okText={i.t('def:yes')}
                        cancelText={i.t('def:no')}
                    >
                        <Button
                            type="dashed"
                            shape="circle"
                            danger
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>
                </Space>
            )
        }
    ]

    return(
        <>
            <Titles
                title={i.t('def:managers.list')}
                buttons={buttons}
            />

            <Table
                columns={columns}
                dataSource={__admin.adminList}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(__admin.pagination.page),
                    pageSize: __admin.pagination.limit,
                    total: __admin.pagination.total,
                    showSizeChanger: false,
                    // onChange: (e) => __admin.setPage(e)
                }}
            />

        </>
    )
})


export const AdminDetail = observer(() => {

    let { id }: any = useParams()

    useEffect(() => {
        return () => {
            __admin.getOne(id)
        }
    }, [id])

    const buttons = [
        { title: i.t('def:actions.backList'), href: `/settings/managers`, type: 'dashed' },
        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
    ]

    return(
        <form onSubmit={(e: FormEvent) => __admin.saveManager(e)}>
            <Titles
                title={i.t('def:managers.detail')}
                buttons={buttons}
            />

            <Row gutter={[20, 20]}>
                <Col span={12}>
                    <label>{i.t('def:type')}</label>
                    <Select
                        style={{ width: '100%' }}
                        value={__admin.admin.right.rights}
                        options={[
                            {label: i.t('def:managers.admin'), value: 0},
                            {label: i.t('def:managers.operator'), value: 1},
                            {label: i.t('def:managers.picker'), value: 2, disabled: !userController.isModule(0)},
                            {label: i.t('def:managers.courier'), value: 3, disabled: !userController.isModule(1)},
                            {label: i.t('def:managers.seller'), value: 4, disabled: !userController.isModule(2)},
                        ]}
                        onChange={(e: number) => runInAction(() => __admin.admin.right.rights = e)}
                    />
                </Col>
                <Divider />
                <Col span={24}>
                    <label>{i.t('def:personalData')}</label>
                </Col>
                <Col span={12}>
                    <label>{i.t('def:personName')}</label>
                    <Input
                        value={__admin.admin.firstname}
                        onChange={(e) => runInAction(() => __admin.admin.firstname = e.target.value)}
                        disabled={__admin.admin.id > 0 && __admin.this_user_id !== __admin.admin.id}
                    />
                </Col>
                <Col span={12}>
                    <label>{i.t('def:personLastName')}</label>
                    <Input
                        value={__admin.admin.lastname}
                        onChange={(e) => runInAction(() => __admin.admin.lastname = e.target.value)}
                        disabled={__admin.admin.id > 0 && __admin.this_user_id !== __admin.admin.id}
                    />
                </Col>
                <Divider />
                <Col span={24}>
                    <label>{i.t('def:personalContacts')}</label>
                </Col>
                <Col span={12}>
                    <label>Email</label>
                    <Input
                        value={__admin.admin.email}
                        onChange={(e) => runInAction(() => __admin.admin.email = e.target.value)}
                        disabled={__admin.admin.id > 0 && __admin.this_user_id !== __admin.admin.id}
                    />
                </Col>
                <Col span={12}>
                    <label>Telegram ID</label>
                    <Input
                        value={__admin.admin.data ? __admin.admin.data.telegram_id : ''}
                        onChange={(e) => runInAction(() => __admin.admin.data.telegram_id = e.target.value)}
                    />
                </Col>
                <Divider />
                {__admin.this_user_id === __admin.admin.id || __admin.admin.id === 0
                    ? <>
                        <Col span={24}>
                            <label>{i.t('def:personalPassword')}</label>
                        </Col>
                        <Col span={12}>
                            <label>{i.t('def:password.password')}</label>
                            <Input.Password
                                value={__admin.managerChangePassword.password}
                                onChange={(e) => runInAction(() => __admin.managerChangePassword.password = e.target.value)}
                            />
                        </Col>
                        <Col span={12}>
                            <label>{i.t('def:password.passwordConfirm')}</label>
                            <Input.Password
                                value={__admin.managerChangePassword.confirm_password}
                                onChange={(e) => runInAction(() => __admin.managerChangePassword.confirm_password = e.target.value)}
                            />
                        </Col>
                        <Divider />
                    </> : <></>
                }


                <Col span={24}>
                    <h2>{i.t('def:managers.notificationsOrder')}:</h2>
                </Col>
                {storeLocal.get('pricing_plan') < 1
                    ? <Col span={24}>
                        <Alert
                            message={i.t('def:errors.pricing_plan')}
                            type="warning"
                        />
                    </Col>
                    : ''}
                <Col span={24}>
                    <Space>
                        <Switch
                            size="small"
                            checked={__admin.admin.right.notifications}
                            onChange={(e) => runInAction(() => __admin.admin.right.notifications = e)}
                        />
                        <label>{i.t('def:getNotifications')}</label>
                    </Space>
                </Col>
                <Col span={24} className={storeLocal.get('pricing_plan') < 2 ? 'opacity_plan' : ''}>
                    <Row gutter={[20, 20]}>
                        {__admin.delivery_zones.length
                            ? <>
                                <Col span={24}>
                                    <label>{i.t('def:managers.deliveryZones')}</label>
                                    <p>{i.t('def:managers.items')}</p>
                                    <Select
                                        mode="multiple"
                                        size="middle"
                                        placeholder={i.t('def:managers.selectDeliveryZones')}
                                        onChange={(e) => runInAction(() => __admin.managerRights.delivery_zones = e)}
                                        value={__admin.managerRights.delivery_zones}
                                        style={{ width: '100%' }}
                                        options={__admin.delivery_zones.map((el: any) => ({
                                            value: el.id,
                                            label: el.name
                                        }))}
                                        disabled={storeLocal.get('pricing_plan') < 1}
                                    />
                                </Col>
                            </>
                            : <></>
                        }
                        {__admin.places.length
                            ? <>
                                <Col span={24}>
                                    <label>{i.t('def:managers.points')}</label>
                                    <p>{i.t('def:managers.items')}</p>
                                    <Select
                                        mode="multiple"
                                        size="middle"
                                        placeholder={i.t('def:managers.selectPoints')}
                                        onChange={(e) => runInAction(() => __admin.managerRights.places = e)}
                                        value={__admin.managerRights.places}
                                        style={{ width: '100%' }}
                                        options={__admin.places.map((el: any) => ({
                                            value: el.id,
                                            label: `${el.name} - ${el.address}`
                                        }))}
                                        disabled={storeLocal.get('pricing_plan') < 1}
                                    />
                                </Col>
                            </>
                            : <></>
                        }
                        {__admin.warehouses.length
                            ? <>
                                <Col span={24}>
                                    <label>{i.t('def:managers.warehouses')}</label>
                                    <p>{i.t('def:managers.items')}</p>
                                    <Select
                                        mode="multiple"
                                        size="middle"
                                        placeholder={i.t('def:managers.selectWarehouses')}
                                        onChange={(e) => runInAction(() => __admin.managerRights.warehouses = e)}
                                        value={__admin.managerRights.warehouses}
                                        style={{ width: '100%' }}
                                        options={__admin.warehouses.map((el: any) => ({
                                            value: el.id,
                                            label: el.name
                                        }))}
                                        disabled={storeLocal.get('pricing_plan') < 1}
                                    />
                                </Col>
                            </>
                            : <></>
                        }
                    </Row>
                </Col>

                <Divider />













                <Col span={24}>
                          <h2>{i.t('def:managers.access')}:</h2>
                      </Col>
                      {storeLocal.get('pricing_plan') < 1
                        ? <Col span={24}>
                            <Alert
                              message={i.t('def:errors.pricing_plan')}
                              type="warning"
                            />
                        </Col>
                        : ''}
                      <Col span={24} className={storeLocal.get('pricing_plan') < 2 ? 'opacity_plan' : ''}>
                          <Row gutter={[20, 20]}>
                              {__admin.delivery_zones.length
                                ? <>
                                    <Col span={24}>
                                        <label>{i.t('def:managers.deliveryZones')}</label>
                                        <p>{i.t('def:managers.itemsAccess')}</p>
                                        <Select
                                          mode="multiple"
                                          size="middle"
                                          placeholder={i.t('def:managers.selectDeliveryZones')}
                                          onChange={(e) => runInAction(() => __admin.managerAccess.delivery_zones_access = e)}
                                          value={__admin.managerAccess.delivery_zones_access}
                                          style={{ width: '100%' }}
                                          options={__admin.delivery_zones.map((el: any) => ({
                                              value: el.id,
                                              label: el.name
                                          }))}
                                          disabled={storeLocal.get('pricing_plan') < 3}
                                        />
                                    </Col>
                                </>
                                : <></>
                              }
                              {__admin.places.length
                                ? <>
                                    <Col span={24}>
                                        <label>{i.t('def:managers.points')}</label>
                                        <p>{i.t('def:managers.itemsAccess')}</p>
                                        <Select
                                          mode="multiple"
                                          size="middle"
                                          placeholder={i.t('def:managers.selectPoints')}
                                          onChange={(e) => runInAction(() => __admin.managerAccess.places_access = e)}
                                          value={__admin.managerAccess.places_access}
                                          style={{ width: '100%' }}
                                          options={__admin.places.map((el: any) => ({
                                              value: el.id,
                                              label: `${el.name} - ${el.address}`
                                          }))}
                                          disabled={storeLocal.get('pricing_plan') < 3}
                                        />
                                    </Col>
                                </>
                                : <></>
                              }
                              {__admin.warehouses.length
                                ? <>
                                    <Col span={24}>
                                        <label>{i.t('def:managers.warehouses')}</label>
                                        <p>{i.t('def:managers.itemsAccess')}</p>
                                        <Select
                                          mode="multiple"
                                          size="middle"
                                          placeholder={i.t('def:managers.selectWarehouses')}
                                          onChange={(e) => runInAction(() => __admin.managerAccess.warehouses_access = e)}
                                          value={__admin.managerAccess.warehouses_access}
                                          style={{ width: '100%' }}
                                          options={__admin.warehouses.map((el: any) => ({
                                              value: el.id,
                                              label: el.name
                                          }))}
                                          disabled={storeLocal.get('pricing_plan') < 3}
                                        />
                                    </Col>
                                </>
                                : <></>
                              }
                          </Row>
                      </Col>
            </Row>
        </form>
    )

})